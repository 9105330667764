import React from "react";
import { ApiContext } from "../../../../../../../api/contexts/ApiContext";
import "./style/project_form.scss";
import {ApiEngine} from "api-engine";

interface ProjectFormProps {
  callback: any
}

interface ProjectFormState {
  title: string | null
  description: string | null
  error: string | null
}

export default class ProjectForm extends React.Component<ProjectFormProps, ProjectFormState> {
  constructor(_props: ProjectFormProps) {
    super(_props);
    this.state = {
      title: null,
      description: null,
      error: null
    }
    this.createProject = this.createProject.bind(this);
  }

  createProject(api: ApiEngine) {
    const me = this;
    const projectParams = {
      title: this.state.title,
      description: this.state.description
    };
    api.asyncFetch("/api/users/projects/new", {method: "POST", body: JSON.stringify(projectParams)}).then((_project) => {
      me.props.callback();
    }).catch((_err) => {
      me.setState({error: JSON.stringify(_err)})
    });
  }

  render() {
    const me = this;
    return <div className={"project-form"} onClick={(_ev) => {_ev.stopPropagation();}}>
      <h3 style={{color: "var(--black)"}}>Новый проект</h3>
      <hr/>
      <input onChange={(_ev: React.ChangeEvent<HTMLInputElement>) => {
        me.setState({title: _ev.currentTarget.value})
      }} placeholder={"Название"}/>
      <textarea onChange={(_ev: React.ChangeEvent<HTMLTextAreaElement>) => {me.setState({description: _ev.currentTarget.value})}} className={"mt-10"} placeholder={"Описание"}></textarea>
      <br/>
      <ApiContext.Consumer>
        {
          ({api, user}) => {
            return <>
              { api && <button className={"ml-auto"} onClick={() => {me.createProject(api)}}>Создать</button> }
            </>
        }
        }
      </ApiContext.Consumer>

    </div>;
  }
}