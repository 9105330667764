import React from "react";
import {ApiEngine} from "api-engine";
import ModalContainer from "../../../../../../../common/layout/modal/ModalContainer";
import "./styles/ai_questions.scss";

interface AiQuestionsProps {
    api: ApiEngine
    project?: any
    dataSource?: any
}

interface AiQuestionsState {
    aiQuestions: any[] | null,
    creatingQuestion: boolean,
    newQuestionContent: string
    loading: boolean
}

export default class AiQuestions extends React.Component<AiQuestionsProps, AiQuestionsState> {
    mount: Boolean = false

    constructor(_props: AiQuestionsProps) {
        super(_props);
        this.state = {
            aiQuestions: null,
            creatingQuestion: false,
            newQuestionContent: "",
            loading: false
        }

        this.getData = this.getData.bind(this);
        this.registerQuestion = this.registerQuestion.bind(this);
        this.getUrlForProject = this.getUrlForProject.bind(this);
        this.getUrlForDataSource = this.getUrlForDataSource.bind(this);
    }

    componentDidMount() {
        if (this.mount) return
        this.mount = true
        this.getData()
    }

    getUrlForProject(): string {
        return `/api/ai_questions/for_project/${this.props.project.id}`
    }

    getUrlForDataSource(): string {
        return `/api/ai_questions/for_data_source/${this.props.dataSource.id}`
    }

    getData() {
        const me = this;
        let url = "";
        if (me.props.dataSource) {
            url = me.getUrlForDataSource()
        }
        if (me.props.project) {
            url = me.getUrlForProject()
        }
        me.props.api.asyncFetch(url, {}).then((_res: any) => {
            me.setState({
                loading: false,
                creatingQuestion: false,
                aiQuestions: _res.aiQuestions ? _res.aiQuestions : []
            });
        })
    }

    registerQuestion() {
        const me = this;
        let url = "";
        if (me.props.dataSource) {
            url = me.getUrlForDataSource()
        }
        if (me.props.project) {
            url = me.getUrlForProject()
        }
        const body = JSON.stringify({
            content: me.state.newQuestionContent
        })
        me.setState({
            loading: true
        }, () => {
            me.props.api.asyncFetch(url, {method: "POST", body}).then((_res: any) => {
                me.getData()
            })
        })
    }


    render() {
        const me = this;
        if (null === me.state.aiQuestions) return <div className={"default_questions card ml-10"}>
            <h3>Вопросы ИИ к каждому кадру</h3>
            <p>Загрузка</p>
        </div>

        return <div className={"default_questions card ml-10"}>
            <h3>Вопросы ИИ к каждому кадру</h3>
            <br/>
            <button onClick={(ev) => {
                me.setState({creatingQuestion: true})
            }}
                    className={"mb-15 w-100"}
            ><span className={"mr-10"}>ДОБАВИТЬ ВОПРОС ДЛЯ ВСЕХ МАТЕРИАЛОВ</span><i className={"m-0 fal fa-plus"}/></button>
            {
                me.state.aiQuestions.length > 0 ?
                    me.state.aiQuestions.map((_q) => {
                        return <div className={"data-source-widget"}>{_q.content}</div>
                    })
                    :
                    <p>Пока нет вопросов, ИИ будет отвечать только на вопрос "Что тут происходит?"</p>
            }

            { me.state.creatingQuestion &&
                <ModalContainer onBackDropClick={() => {
                    me.setState({
                        creatingQuestion: false
                    })
                }}>
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        className={"card new-question"} style={{color: "var(--black)"}}>
                        <h3>Новый вопрос</h3>
                        <textarea onChange={(_e) => {
                            me.setState({
                                newQuestionContent: _e.target.value
                            })
                        }} placeholder={"Формулировка вопроса"} />
                        { me.state.newQuestionContent &&
                            <button onClick={me.registerQuestion}
                                    style={me.state.loading ? {pointerEvents: "none", opacity: 0.6} : {}}
                            >Создать</button>
                        }
                    </div>
                </ModalContainer>
            }
        </div>
    }
}