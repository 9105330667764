import React from "react";
import Logo from "../../../common/logo/Logo";
import CursorFollower from "../../../external/cursor-follower/CursorFollower";
import "./landing.scss";

export default class Landing extends React.Component<any, any> {

  render() {
    return <div className={"landing-page"}>
      <div className="logo-container" style={{width: "100%", flexDirection: "column", justifyContent: "center"}}>
        <div className={"v-spacer-3"}></div>

        <Logo width={window.innerWidth * 0.05}
              color={"rgba(91,132,161,0.51)"}
              style={{marginLeft: "auto", marginRight: "auto"}}/>
        <div className={"v-spacer-6"}></div>
      </div>
      <div className={"centered-points"}>
        {/* <h1 style={{opacity: 0.5, color: "var(--color-primary)"}} className={"ml-auto mr-auto"}>вИИзуал</h1> */}
        <div className={"points-themselves"} >
          {[
            {
              icon: "fa-images",
              text: "Удобная разметка"
            },
            {
              icon: "fa-search",
              text: "Поиск по медиаматериалам"
            },
            {
              icon: "fa-eye",
              text: "Распознавание в реальном времени"
            },
            {
              icon: "fa-cloud", 
              text: "Легкая интеграция"
            },
            {
              icon: "fa-cloud-download",
              text: "Экспортирование нейросетей"
            }
          ].map((point, index) => (
            <div key={index} className={"point"}>
              <i className={`fal ${point.icon} fa-5x`}></i>
              <div className={"v-spacer-2"}></div>
              <h3 style={{}}>{point.text}</h3>
            </div>
          ))}
        </div>
        <br/>
        <br/>
        <div className={"points-themselves"} >
          {[
            {
              icon: "fa-code-branch",
              text: "Управление предметной областью"
            },
            {
              icon: "fa-lightbulb",
              text: "Новые инсайты"
            },
            {
              icon: "fa-chart-network",
              text: "Мультиагентный подход"
            },
            {
              icon: "fa-code",
              text: "API"
            },
            {
              icon: "fa-network-wired",
              text: "Распределенные вычисления"
            }
          ].map((point, index) => (
            <div key={index} className={"point"}>
              <i  className={`fal ${point.icon} fa-5x`}></i>
              <div className={"v-spacer-2"}></div>
              <h3 style={{}}>{point.text}</h3>
            </div>
          ))}
        </div>
      </div>
      <div className={"v-spacer-7"}></div>

    </div>
  }
}