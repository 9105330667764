import React from "react";
import { ApiContext } from "../../../api/contexts/ApiContext";
import ApiProvider from "../../../api/providers/ApiProvider";
import "./styles/cabinet.scss"
import LeftMenu from "./left_menu/LeftMenu";
import CabinetContent from "./content/CabinetContent";
export default class Cabinet extends React.Component<any, any> {
  render() {
    return <ApiProvider>
        <ApiContext.Consumer>
            {({api, user}) => {
                return <div className={"cabinet"}>
                    <LeftMenu/>
                    <CabinetContent/>
                </div>
            }}
        </ApiContext.Consumer>
    </ApiProvider>
      ;
  }
}