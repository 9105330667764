import React from "react";
import "./styles/user-registration.scss";
import {ApiEngine} from "api-engine";

interface UserRegistrationProps {
    api: ApiEngine
    onClose: () => void
}

interface UserRegistrationState {
    email: string
    password: string
    result: string
    error: string
    loading: boolean
}

export default class UserRegistration extends React.Component<UserRegistrationProps, UserRegistrationState> {
    mount: Boolean = false

    constructor(_props: UserRegistrationProps) {
        super(_props);
        this.state = {
            email: "",
            password: "",
            result: "",
            error: "",
            loading: false
        }
        this.signUp = this.signUp.bind(this);
    }

    componentDidMount() {
        if (this.mount) return;
        this.mount = true;
    }

    signUp() {
        const me = this;
        me.setState({
            loading: true
        }, () => {
            me.props.api.asyncFetchWithoutQueing("/api/users/common/sign_up_and_sign_in", {
                method: "POST",
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })}).then((_res: any) => {
                    if (_res.jwt) {
                        me.setState({
                            result: "Регистрация выполнена успешно"
                        }, () => {
                            localStorage.setItem("jwt", _res.jwt)
                            setTimeout(() => { document.location.href = "/cabinet"; }, 1000);

                        })
                    }
                }).catch((_err) => {
                    me.setState({loading: false, error: JSON.stringify(_err)})
                }
            )
        })
    }

    render() {
        const me = this;
        return <div className={"user-registration"}>
            <div className={"user-registration-content"}>
                <h1>Регистрация</h1>

                <form>
                    <input placeholder={"Email"}
                           type={"email"}
                           onChange={(e) => me.setState({email: e.target.value})}/>
                    <input placeholder={"Пароль"}
                           type={"password"}
                           onChange={(e) => me.setState({password: e.target.value})}/>
                    {me.state.result && <p style={{fontWeight: "900", color: "var(--green_400)", marginBottom: "10px"}}>{me.state.result}</p>}
                    {me.state.error && <p style={{fontWeight: "900", color: "var(--red)", marginBottom: "10px"}}>{me.state.error}</p>}
                    { me.state.loading ? <p>Регистрация</p>
                        :
                        <>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    me.signUp()
                                }}
                                style={(me.state.email && me.state.password) ? {opacity: 1} : {opacity: 0.4, pointerEvents: "none"}}>Регистрация</button>
                            <p onClick={() => {
                                if (me.props.onClose) me.props.onClose();
                            }}>Отмена</p>
                        </>
                    }
                </form>
            </div>
        </div>
    }
}