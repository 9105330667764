import React from "react";
import {ApiEngine} from "api-engine";
import {LabelCardsViewMode} from "./enums/LabelCardsViewMode";
import AllListViewMode from "./label_cards/all_list/AllListViewMode";
import ListByTime from "./label_cards/list_by_time/ListByTime";
import ListByClassificators from "./label_cards/list_by_classificators/ListByClassificators";
import LabelCard from "../../models/LabelCard";

interface LabelsListProps {
  api: ApiEngine
  labels: any[]
  rectangleCoordinates: any[]
  deleteLabelCard: (card: any) => void
  addLabelCard: (newLabelCard: LabelCard, rectangleCoordinates: number[]) => void
  onHover: (title: string) => void
  setTime: (time: number) => void
}

interface LabelsListState {
  viewMode: LabelCardsViewMode
  labels: any[]
  blockedLabelsIndexes: any
}

export default class LabelsList extends React.Component<LabelsListProps, LabelsListState> {
  constructor(props: LabelsListProps) {
    super(props);
    this.state = {
      viewMode: LabelCardsViewMode.BY_OBJECT,
      labels: this.props.labels,
      blockedLabelsIndexes: {}
    };

    this.deleteLabel = this.deleteLabel.bind(this);
  }

  deleteLabel(label: any) {
    const me = this;
    const myIndex = me.state.labels.map((x) => {
      return x.id
    }).indexOf(label.id);
    if (-1 === myIndex) {
      alert("Ошибка удаления")
      return
    }
    let blockedIndexes = {...me.state.blockedLabelsIndexes};
    blockedIndexes[label.id] = true;
    me.setState({
        blockedLabelsIndexes: blockedIndexes
      },() => {
      me.props.deleteLabelCard(label);
    })
  }

  render() {
    const me = this;
    const btns = [["fa-list", LabelCardsViewMode.ALL], ["fa-clock", LabelCardsViewMode.BY_TIME], ["fa-vector-square", LabelCardsViewMode.BY_OBJECT]];
    return <div>
      { me.state.labels.length === 0 && <p className={"mb-20"}>Файл не размечен</p>}
      <div className={"flex flex-row justify-evenly mb-10"}>
        {btns.map((x: any, _xIndex: number) => {
          return <button className={"w-80 mr-10"}
                         key={`button-for-label-${_xIndex}`}
                         style={
                           x[1] === me.state.viewMode ? {background: "var(--red)",  fontSize: "12px", padding: "5px 10px"} : { background: "var(--gray_600)", fontSize: "12px", padding: "5px 10px"}
                         }
                         onClick={() => {
                           me.setState({
                             viewMode: x[1]
                           })
                         }}
          >
            <i className={`w-100 fal ${x[0]}`}/>
          </button>
        })
        }
      </div>

      { me.state.labels && me.state.labels.length > 0 &&
          <div className="container" style={{
            minHeight: "50vh", maxHeight: "50vh", overflowY: "scroll"
          }}>
            {
              me.state.viewMode === LabelCardsViewMode.ALL &&
                <AllListViewMode
                    key={JSON.stringify(me.state.labels)}
                    deleteLabelCard={me.deleteLabel}
                    labelCards={me.state.labels}
                    rectangleCoordinates={
                      me.props.rectangleCoordinates
                    }
                    onSaveLabel={me.props.addLabelCard}
                    onLabelClick={(x) => {
                      me.props.setTime(x);
                    }
                    }
                    blockedLabelsIndexes={me.state.blockedLabelsIndexes}
                    onHover={(_title: string) => {
                      me.props.onHover(_title)
                    }}
                />
            }

            {
              me.state.viewMode === LabelCardsViewMode.BY_TIME &&
                <ListByTime
                    deleteLabelCard={me.deleteLabel}
                    labelCards={me.state.labels}
                    rectangleCoordinates={me.props.rectangleCoordinates}
                    onSaveLabel={me.props.addLabelCard}
                    onLabelClick={(x) => {
                      me.props.setTime(x);
                    }
                    }
                    blockedLabelsIndexes={me.state.blockedLabelsIndexes}
                    onHover={me.props.onHover}
                />
            }

            {
              me.state.viewMode === LabelCardsViewMode.BY_OBJECT &&
                <ListByClassificators
                    deleteLabelCard={me.deleteLabel}
                    labelCards={me.state.labels}
                    onSaveLabel={me.props.addLabelCard}
                    onLabelClick={(x) => {
                      me.props.setTime(x);
                    }}
                    blockedLabelsIndexes={me.state.blockedLabelsIndexes}
                    onHover={(_title: string) => {
                      me.props.onHover(_title)
                    }}
                    rectangleCoordinates={me.props.rectangleCoordinates}/>
            }
          </div>
      }
    </div>;
  }
}