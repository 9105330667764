import React from "react";
import "./style/data_source_file_form.scss";
import {ApiEngine} from "api-engine";

interface DataSourceFileProps {
    dataSource: any,
    project: any,
    callback: any,
    api: ApiEngine,
    onLoadingStateChange: (isLoading: boolean) => void
}

interface DataSourceFileState {
    title: string | null
    description: string | null
    error: string | null
    loading: boolean
}

export default class DataSourceFileForm extends React.Component<DataSourceFileProps, DataSourceFileState> {
    private fileRef: React.RefObject<HTMLInputElement>;

    constructor(_props: DataSourceFileProps) {
        super(_props);
        this.state = {
            title: null,
            description: null,
            loading: false,
            error: null
        };
        this.fileRef = React.createRef<HTMLInputElement>();
        this.sendData = this.sendData.bind(this);
    }

    sendData() {
        const me = this;
        let fileData = new FormData();
        setTimeout(() => me.props.onLoadingStateChange(true), 100);
        fileData.append("file", me.fileRef.current?.files!![0]!!);
        let prevState = {...me.state};
        prevState.loading = true;
        prevState.error = "";
        let params = {
            title: me.state.title,
            description: me.state.description,
            data_source_id: me.props.dataSource.id,
            project_id: me.props.project.id,
        }
        fileData.append("params", JSON.stringify(params));
        const url = `/api/projects/data_sources/data_source_files/new`;

        me.setState(prevState, () => {
            me.props.api.asyncFetch(url, {
                method: "POST",
                body: fileData
            }).then((_res) => {
                me.props.callback(_res);
                me.props.onLoadingStateChange(true);

            }).catch((err) => {
              me.props.onLoadingStateChange(true);
                me.setState({error: "Не удалось сохранить файл. Пожалуйста, попробуйте еща раз позже", loading: false});
            })
        });
    }

    render() {
        const me = this;
        return <div className={"data-source-file-form"}
                    onClick={(_ev) => {
                        _ev.stopPropagation();
                    }}>
            <h3>Новый файл</h3>
            <hr/>
            <input
                onChange={(_ev) => {
                    me.setState({title: _ev.currentTarget.value})
                }}
                className={"mb-10"}
                style={me.state.loading ? {opacity: 0.6, pointerEvents: "none", color: "var(--black)"} : {}}
                placeholder={"Краткое описание файла"}/>
            <input
                onChange={(_ev) => {
                    me.setState({description: _ev.currentTarget.value})
                }}
                className={"mb-10"}
                style={me.state.loading ? {opacity: 0.6, pointerEvents: "none", color: "var(--black)"} : {}}
                placeholder={"Описание"}/>
            <input
                style={me.state.loading ? {
                    opacity: 0.6,
                    pointerEvents: "none",
                    color: "var(--black)"
                } : {color: "var(--black)"}}
                ref={me.fileRef}
                className={"mb-10"}
                type={"file"}
                placeholder={"Файл"}/>
            {me.state.error &&
                <p className={"mt-2 mb-6"} style={{maxWidth: "70%", color: "var(--red)"}}>{me.state.error}</p>}
            <button onClick={() => {
                me.sendData()
            }} className={`${me.state.loading ? 'disabled' : ''}`}>{me.state.loading ? "Загрузка" : "Создать"}</button>
        </div>
    }
}