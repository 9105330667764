import React from "react";
import Breadcrumbs from "../../../../../../../../../../../common/breadcrumbs/Breadcrumbs";
import User from "../../../../../../../../../common/models/User";
import {ApiEngine} from "api-engine";
import LabelerClass from "./labeler/LabelerClass";
import ImageLabeler from "./labeler/ImageLabeler";

interface DataSourceLabelingControlProps {
  dataSourceFileId: any
  dataSource: any
  project: any
  user: User
  api: ApiEngine
}

interface DataSourceLabelingControlState {
  dataSourceFile: any
  dataLoading: boolean
}

export default class DataSourceLabelingControl extends React.Component<DataSourceLabelingControlProps, DataSourceLabelingControlState> {
  mounted = false;

  constructor(_props: any) {
    super(_props);
    this.state = {
      dataSourceFile: null,
      dataLoading: false
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return
    this.mounted = true;
    this.getData()
  }

  getData() {
    const me = this;
    const url = `/api/projects/data_sources/${me.props.dataSource.id}/data_source_files/${me.props.dataSourceFileId}/show`;
    me.props.api.asyncFetch(url, {}).then((_res) => {
      const labelsUrl = `/api/projects/data_sources/data_source_files/${_res.id}/labels/index`;
      me.props.api.asyncFetch(labelsUrl, {}).then((_resTwo) => {
        let data = {..._res};
        // alert(JSON.stringify(_resTwo))
        data.labels = [];
        if (_resTwo.labels) data.labels = _resTwo.labels;
        me.setState({dataSourceFile: data, dataLoading: false})
      })
    })
  }

  render() {
    const me = this;
    return <>
      <Breadcrumbs>
        <a href={"/cabinet/projects"}>Проекты</a>
        <i className={"fal fa-angle-right"}/>
        <div onClick={() => {document.location.href = `/cabinet/projects/${me.props.project.id}`;}} className={"breadcrumb-project-name"}>
          <h3>{me.props.project.title}</h3>
          <p>{me.props.project.description}</p>
        </div>
        <i className={"fal fa-angle-right"}/>
        <div className={"breadcrumb-project-name"} onClick={() => {document.location.href = `/cabinet/projects/${me.props.project.id}/data_sources/${me.props.dataSource.id}`;}}>
          <h3>{me.props.dataSource.title}</h3>
          <p>{me.props.dataSource.description}</p>
        </div>
        <i className={"fal fa-angle-right"}/>
        { me.state.dataSourceFile &&
            <div className={"breadcrumb-project-name"}>
                <h3>{me.state.dataSourceFile.title}</h3>
                <p>{me.state.dataSourceFile.description}</p>
            </div>
        }
      </Breadcrumbs>
      { me.state.dataSourceFile &&
        <>
          <div className={"flex column"}>
            {/*<Labeler api={me.props.api}*/}
            {/*         project={me.props.project}*/}
            {/*         dataSource={me.props.dataSource}*/}
            {/*         dataSourceFile={me.state.dataSourceFile}*/}
            {/*         imageUrl={me.state.dataSourceFile.file_url}/>*/}
            { me.state.dataSourceFile.file_url.toLowerCase().match(/\.(mp4|webm|ogg)$/) &&
              <LabelerClass   api={me.props.api}
                              dataLoading={me.state.dataLoading}
                              project={me.props.project}
                              dataSource={me.props.dataSource}
                              dataSourceFile={me.state.dataSourceFile}
              />
            }
            { me.state.dataSourceFile.file_url.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) &&
              <ImageLabeler  api={me.props.api}
                             dataLoading={me.state.dataLoading}
                             project={me.props.project}
                             dataSource={me.props.dataSource}
                             dataSourceFile={me.state.dataSourceFile}
                             user={me.props.user}
              />
            }
            {/*{ me.state.dataSourceFile.labels.length > 0 &&*/}
            {/*    <SearchResults api={me.props.api}*/}
            {/*         project={me.props.project}*/}
            {/*         dataSource={me.props.dataSource}*/}
            {/*         dataSourceFile={me.state.dataSourceFile}*/}
            {/*    />*/}

            {/*}*/}
          </div>
          </>
      }


    </>
  }
}