import React from "react";
import "../common/article.scss";
export default class AboutUs extends React.Component<any, any> {
  render() {
    return <div className={"article mr-auto ml-auto w-80 mt-20"}>
      <h1>О нас</h1>
      <p>вИИзуал делает машинное зрение доступным всем и является инструментом, чтобы быть в курсе всего в компании.</p>
      <p>В течение 10 лет наша компания занималась заказной разработкой для разных компаний, и у всех была проблема с оптимальным использованием данных. Наименее эффективной областью для автоматической добычи инсайтов была и пока еще остается область фото и видеоданных: тысячи гигабайт лежат и практически никак не используются.</p>
      <p>Системы Google Photo и Фотопленка Apple давно уже решают подобную задачу - медиаматериалы проходят базовую категоризацию. Аналитика рынка подобных решений показала, что в основном такая технология была использована внутри крупнейших корпораций вследствие дороговизны разработки и внедрения.</p>
      <p>Современные открытые технологии демократизировали системы машинного зрения, больших языковых моделей и сред их исполнения. После этого мы решили попробовать запустить свою систему, позволяющую подключаться к озерам данных и индексировать их с помощью нескольких ИИ агентов из разных модальностей. Результаты оказались крайне полезными для решения бизнес задач и было принято решение создать продукт исходя из гипотезы, что внедрение мультагентного многомодального поиска может оказать значительный экономический эффект в бизнесе.</p>
      <p>Проект получил название <b>вИИзуал</b> (система опирается в основном на зрительную информацию и использует ИИ).</p>
      <h2>ИТАК</h2>
      <p>вИИзуал - это умная видеоаналитика, система поиска по медиаматериалам. Основной целью является максимальное упрощение использования систем компьютерного зрения для людей.</p>
      <h2>Вкратце о машинном зрении</h2>
      <p>Машинное зрением - технология искусственного интеллекта, использующая алгоритмы и нейросети для извлечения значимой информации из цифровых изображений, видео и иных визуальных потоков ввода.</p>
      <h3>Основные задачи машинного зрения и системы вИИзуал</h3>
      <ul>
        <li><b>Обнаружение объектов</b> – поиск и сопоставление частей изображения с одной из заранее известных категорий</li>
        <li><b>Классификация изображений</b> - сопоставление изображения с одной из заранее известных категорий</li>
        <li><b>Описание одержимого изображения или кадра видео</b></li>
        <li><b>Распознавание текста</b></li>
        <li><b>Сопоставление изображений</b> – поиск совпадающих признаков у набора изображений</li>
        <li><b>Распознавание лиц и людей</b> – сопоставление изображения с одной из заранее известных личностей из заранее заданного набора</li>
        <li><b>Сегментация изображений</b> – разделение изображения на значимые части</li>
        <li><b>Поиск границ</b> – идентификация границ между объектами изображения</li>
        <li><b>Восстановление изображения</b> – реконструкция изображения с улучшенными характеристиками</li>
        <li><b>Трехмерная реконструкция</b> – восстановление трехмерной сцены на основании одного или нескольких снимков</li>
      </ul>
      <h2>Как мы можем помочь</h2>
      <p>В рамках системы вы имеете возможность загрузить свои фото и видеоданные, разметить их, либо предоставить ИИ заняться этим. При необходимости обучить своего ИИ агента со своей моделью машинного зрения и начать его использовать.</p>
      <h2>О будущем</h2>
      <p>Cнижение сложности интеграции систем человек-машина и машина-машина открывает возможность радикальной модернизации большинства областей человеческой деятельности.</p>
    </div>;
  }
}