import React, {Dispatch, MouseEvent, SetStateAction, useEffect, useRef} from "react";
import LabelCard from "../../models/LabelCard";
import Rectangle from "./Rectangle";
import "../styles/photo_display.scss";

interface PhotoDisplayProps {
  width: number | null;
  height: number | null;
  duration: number;
  labels: LabelCard[];
  initialCurrentTime: number;
  imageUrl: string;
  allRectangleCoordinates: number[][];
  setAllRectangleCoordinates: Dispatch<SetStateAction<number[][]>>;
  callback: any;
  onRectangleSelect: (startX: number, startY: number, endX: number, endY: number, timeFrame?: number) => void;
  hoveredLabelTitle: string | null;
  changeCurrentTime: (newTime: number) => void
  blockLabeling?: boolean
}

interface PhotoDisplayState {
  startX: number | null;
  startY: number | null;
  endX: number | null;
  endY: number | null;
  currentTime: number;
  isDrawing: boolean;
  playing: boolean;
  duration: number;
  blockScroll: boolean;
}

export default class PhotoDisplayClass extends React.Component<PhotoDisplayProps, PhotoDisplayState> {
  imageRef = React.createRef<HTMLDivElement>();
  controlLineRef = React.createRef<HTMLDivElement>();
  videoRef = React.createRef<HTMLVideoElement>();
  mounted = false;
  constructor(_props: PhotoDisplayProps) {
    super(_props);
    this.state = {
      startX: null,
      startY: null,
      endX: null,
      endY: null,
      currentTime: this.props.initialCurrentTime,
      isDrawing: false,
      playing: false,
      duration: this.props.duration,
      blockScroll: false
    }
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);

    this.handleTouchDown = this.handleTouchDown.bind(this);
    this.handleTouchUp = this.handleTouchUp.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);

    this.onRectangleChange = this.onRectangleChange.bind(this);
  }

  onRectangleChange(fireEvent: Boolean = false) {
    const me = this;
    if (me.state.startX !== null &&
      me.state.startY !== null &&
      me.state.endX !== null &&
      me.state.endY !== null) {
      if (fireEvent) {
        // alert("FIRE")
        me.props.onRectangleSelect(
          Math.min(me.state.startX, me.state.endX) / me.imageRef.current!!.clientWidth,
          Math.min(me.state.startY, me.state.endY) / me.imageRef.current!!.clientHeight,
          Math.max(me.state.startX, me.state.endX) / me.imageRef.current!!.clientWidth,
          Math.max(me.state.startY, me.state.endY) / me.imageRef.current!!.clientHeight,
          me.state.currentTime!!
        );
      }
    }
  }

  componentWillUnmount() {
    // this.videoRef.current?.removeEventListener("timeupdate", () => {});
    this.videoRef.current?.removeEventListener("pause", () => {});
    window.onresize = null;
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    const me = this;
  }

  handleMouseDown(event: MouseEvent<any>) {
    event.stopPropagation();
    const me = this;
    if (me.props.blockLabeling) return;
    const rect = me.imageRef.current!.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    me.setState({
      startX: x,
      startY: y,
      endX: x,
      endY: y,
      isDrawing: true
    }, () => {
      me.onRectangleChange()
    });
  }

  handleTouchDown(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    if (this.props.blockLabeling) return;
    const me = this;
    const rect = me.imageRef.current!.getBoundingClientRect();
    const x = event.touches[0].clientX - rect.left;
    const y = event.touches[0].clientY - rect.top;

    me.setState({
      startX: x,
      startY: y,
      endX: x,
      endY: y,
      isDrawing: true
    }, () => {
      me.onRectangleChange()
    });
  }

  handleMouseUp(event: MouseEvent<any>) {
    event.stopPropagation();
    const me = this;
    if (me.state.startX !== null &&
        me.state.startY !== null &&
        me.state.endX !== null &&
        me.state.endY !== null &&
        me.state.isDrawing) {
      // me.videoRef.current?.pause();
      me.setState({
        isDrawing: false,
        playing: false
      }, () => {
        me.onRectangleChange(true)
      });
    }
  }

  handleTouchUp(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    const me = this;
    if (me.state.startX !== null &&
        me.state.startY !== null &&
        me.state.endX !== null &&
        me.state.endY !== null &&
        me.state.isDrawing) {
      // me.videoRef.current?.pause();
      me.setState({
        isDrawing: false,
        playing: false
      }, () => {
        me.onRectangleChange(true)
      });
    }
  }

  handleMouseMove(event: MouseEvent<any>) {
    const me = this;
    if (!me.state.isDrawing || event.buttons !== 1 || !me.imageRef.current) {
      return;
    }
    const rect = me.imageRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    me.setState({
      endX: x,
      endY: y
    }, () => {
      me.onRectangleChange()
    })
  }

  handleTouchMove(event: React.TouchEvent<HTMLDivElement>) {
    const me = this;
    if (!me.state.isDrawing || !me.imageRef || !me.imageRef.current) {
      return;
    }
    const rect = me.imageRef.current.getBoundingClientRect();
    const x = event.touches[0].clientX - rect.left;
    const y = event.touches[0].clientY - rect.top;
    me.setState({
      endX: x,
      endY: y
    }, () => {
      me.onRectangleChange()
    })
  }



  handleRectangleUpdate(updatedCoordinates: number[], index: number) {
    const me = this;
    const updatedAllRectangleCoordinates = [...me.props.allRectangleCoordinates];
    updatedAllRectangleCoordinates[index] = updatedCoordinates;
    me.props.setAllRectangleCoordinates(updatedAllRectangleCoordinates);
  }

  render() {
    const me = this;

    return (
        <div>

          <div style={{
            zIndex: 1,
            display: "flex",
            position: "absolute",
            "top": 0,
            left: "0",
            width: "100%",
            height: "100%"
          }}>

            <div style={{position: "relative", width: "100%", height: "100%"}}
                 onMouseDown={me.handleMouseDown}
                 ref={me.imageRef}
                 onMouseUp={me.handleMouseUp}
                 onMouseMove={me.handleMouseMove}
                 onTouchStart={me.handleTouchDown}
                 onTouchEnd={me.handleTouchUp}
                 onTouchMove={me.handleTouchMove}
            >

              {/*<img*/}
              {/*    src={me.props.imageUrl.replace(/([^:]\/)\/+/g, "$1")}*/}
              {/*    alt="Uploaded"*/}
              {/*    style={{width: "100%", height: "100%"}}*/}
              {/*    draggable="false"*/}
              {/*/>*/}
              {!me.props.blockLabeling && me.props.allRectangleCoordinates.map((rectangle, index) => {
                const label = me.props.labels[index];
                let hovered = false;
                if (label.title === me.props.hoveredLabelTitle) {
                  hovered = true;
                }
                return (
                    <Rectangle
                        title={label.title}
                        hovered={hovered}
                        key={`${hovered}-${index}`}
                        currentTime={me.state.currentTime}
                        coordinates={rectangle}
                        photoWidth={me.imageRef.current?.getBoundingClientRect().width || 0}
                        photoHeight={me.imageRef.current?.getBoundingClientRect().height || 0}
                        onCoordinatesChange={(updatedCoordinates) => me.handleRectangleUpdate(updatedCoordinates, index)}
                    />
                )
              })}
              {me.state.startX !== null &&
                  me.state.startY !== null &&
                  me.state.endX !== null &&
                  me.state.endY !== null && (
                      <div
                          style={{
                            position: "absolute",
                            border: "2px solid #00FF00",
                            borderRadius: "10px",
                            zIndex: 5,
                            pointerEvents: "none",
                            left: Math.min(me.state.startX, me.state.endX),
                            top: Math.min(me.state.startY, me.state.endY),
                            width: Math.abs(me.state.endX - me.state.startX),
                            height: Math.abs(me.state.endY - me.state.startY),
                          }}
                      />
                  )}
            </div>
          </div>
        </div>
    );
  }
}