import React from "react";
import { ApiContext } from "../../../../api/contexts/ApiContext";
import "./styles/left_menu.scss";
import LeftMenuContent from "./LeftMenuContent";

const buttons = [
  {title: 'Личный кабинет', icon: 'fal fa-user', link: '/cabinet/profile', disabled: true},
  {title: 'Оплата', icon: 'fal fa-money-bill', link: '/cabinet/profile', disabled: true},
  {title: "Обучение", icon: "fal fa-graduation-cap", link: "/cabinet/profile", disabled: true},
  {title: "Помощь", icon: "fal fa-question", link: "/cabinet/profile", disabled: true},
]

export default class LeftMenu extends React.Component<any, any> {
  mounted = false

  constructor(_props: any) {
    super(_props);
    this.state = {
      projects: []
    }
  }

  componentDidMount() {
    if (this.mounted) return
    this.mounted = true
    this.getProjects()
  }

  getProjects() {
    const me = this
  }

  render() {
    const me = this;
    return <ApiContext.Consumer>
      {
        ({api, user}) => {
          return <LeftMenuContent api={api!!} user={user} buttons={buttons} />
        }
      }
      </ApiContext.Consumer>
  }
}