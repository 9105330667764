import React from "react";

interface TypedTextProps {
    message: string;
    onFinishTyping?: () => void;
    noTyping?: boolean
}

interface TypedTextState {
    displayedMessage: string;
    currentIndex: number;
}

export default class TypedText extends React.Component<TypedTextProps, TypedTextState> {
    typingTimer: any;
    mount: Boolean = false;

    constructor(props: TypedTextProps) {
        super(props);
        this.state = {
            displayedMessage: this.props.noTyping ? this.props.message : "",
            currentIndex: 0
        };
    }

    componentDidMount() {
        if (this.mount) return
        this.mount = true
        if (!!this.props.noTyping) {return;}
        this.startTypingAnimation();
    }

    componentWillUnmount() {
        if (this.typingTimer) {
            this.mount = false
            clearTimeout(this.typingTimer);
        }
    }
    startTypingAnimation = () => {
        const typeNextChar = () => {
            if (this.state.currentIndex < this.props.message.length) {
                this.setState({
                    displayedMessage: this.props.message.slice(0, this.state.currentIndex + 1),
                    currentIndex: this.state.currentIndex + 1
                }, () => {
                    this.typingTimer = setTimeout(typeNextChar, 3);
                });
            }
            if (this.props.onFinishTyping) {
                this.props.onFinishTyping();
            }
        };
        this.typingTimer = setTimeout(typeNextChar, 1);
    }

    render() {
        return <p style={{whiteSpace: "pre-wrap", fontFamily: "monospace", fontSize: "14px"}}><i style={{color: "var(--yellow)"}} className={"mr-7 fal fa-stars"}/>{this.state.displayedMessage}
        </p>
    }
}