import React from "react";
import "./styles/data_source_file_widget.scss";
import TypedText from "../../../../../../../../../common/cool_stuff/text/typed_text/TypedText";

export default class DataSourceFileWidget extends React.Component<any, any> {
  mount: Boolean = false

  constructor(_props: any) {
    super(_props);
    this.state = {
      aiDescription: _props.dataSourceFile.ai_description,
    }
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount() {
    if (this.mount) return
    this.mount = true;
    if (!this.state.aiDescription) {
      this.updateData();
    }
  }

  updateData() {
    const me = this;
    const url = `/api/projects/data_sources/${me.props.dataSource.id}/data_source_files/${me.props.dataSourceFile.id}/show`;
    me.props.api.asyncFetch(url, {}).then((_res: any) => {
      me.setState({
        aiDescription: _res.ai_description
      }, () => {
        if (!me.state.aiDescription) {
          setTimeout(me.updateData, (15000 + Math.floor(Math.random()* 10000)));
        }
      })
    })
  }

  render() {
    const me = this;
    return <div className={"data-source-file-widget"} onClick={() => {document.location.href = `/cabinet/projects/${me.props.project.id}/data_sources/${me.props.dataSource.id}/data_source_files/${me.props.dataSourceFile.id}`}}>
      <div className={"flex flex-column"}>
        <p className={"title"}>{me.props.dataSourceFile.title}</p>
        <p className={"description"}>{me.props.dataSourceFile.description}</p>
        <p className={"ai-description"}>
          { me.state.aiDescription ? <>
                <TypedText
                    message={me.state.aiDescription}
                    noTyping={false}/>
              </>
              : <div className={"ai-loading-stars"}>
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="12" r="2" fill="currentColor" opacity="0.3">
                    <animate attributeName="opacity" from="0.3" to="1" dur="0.8s" begin="0s" repeatCount="indefinite" />
                  </circle>
                  <circle cx="12" cy="12" r="2" fill="currentColor" opacity="0.3">
                    <animate attributeName="opacity" from="0.3" to="1" dur="0.8s" begin="0.2s" repeatCount="indefinite" />
                  </circle>
                  <circle cx="20" cy="12" r="2" fill="currentColor" opacity="0.3">
                    <animate attributeName="opacity" from="0.3" to="1" dur="0.8s" begin="0.4s" repeatCount="indefinite" />
                  </circle>
                </svg>
              </div>
          }
        </p>
      </div>
      {/*<div className={"flex row justify-between mt-15"}>*/}
      {/*</div>*/}
      {me.props.dataSourceFile.file_url.toLowerCase().match(/\.(mp4|webm|mov|avi|wmv)$/) &&
        <video autoPlay={true} muted={true} controls={false} src={`${me.props.api.serverUrl}${me.props.dataSourceFile.file_url}`}/>
      }
      {me.props.dataSourceFile.file_url.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) &&
        <img src={`${me.props.api.serverUrl}${me.props.dataSourceFile.file_url}`} alt={me.props.dataSourceFile.title}/>
      }
    </div>
  }
}